import Grid from 'components/Box/Grid';
import Image from 'components/Image/Image';
import Link from 'components/Link';
import { RouteConfig } from 'config/constants/route';
import styled from 'styled-components';

const HunnySocial = () => {
  return (
    <StyledWrapper>
      <Link external href={RouteConfig.Twitter}>
        <Image src="/images/social/twitter.png" width={24} height={25} alt="icon-social-twitter" />
      </Link>
      <Link external href={RouteConfig.Telegram}>
        <Image src="/images/social/telegram.png" width={24} height={25} alt="icon-social-telegram" />
      </Link>
      <Link external href={RouteConfig.Medium}>
        <Image src="/images/social/medium.png" width={24} height={25} alt="icon-social-medium" />
      </Link>
      <Link external href={RouteConfig.Discord}>
        <Image src="/images/social/discord.png" width={24} height={25} alt="icon-social-discord" />
      </Link>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Grid)`
  grid-gap: 16px;
  padding: 8px;

  background: #1c202e;
  position: fixed;
  top: 200px;
  right: -10px;
  width: 50px;
  z-index: ${({ theme }) => theme.zIndices.modal};
  border-radius: ${({ theme }) => theme.radii.large};

  svg {
    max-height: 24px;
    max-width: 24px;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 16px;
    width: 70px;

    svg {
      max-height: 28px;
      max-width: 28px;
    }
  }
`;

export default HunnySocial;
