import Box from 'components/Box/Box';
import Flex from 'components/Box/Flex';
import Text from 'components/Text/Text';
import { TextProps } from 'components/Text/types';
import { usePlayGame } from 'hooks/usePlayGame';
import React from 'react';
import styled, { css } from 'styled-components';
import Button from './Button';
import { ButtonProps } from './types';

enum PlayNowButtonTheme {
  DEFAULT = 'DEFAULT',
}

enum StatusButton {
  default = 'default',
  hover = 'hover',
  active = 'active',
}
type VariantStatusStyled = {
  [key in StatusButton]: {
    boxShadow: string;
    backgroundOutner: string;
    backgroundInner: string;

    borderGradient: string;
    ellipsebackground: string;
    textButton: string;
  };
};
const variants: {
  [key in PlayNowButtonTheme]: VariantStatusStyled;
} = {
  [PlayNowButtonTheme.DEFAULT]: {
    [StatusButton.default]: {
      boxShadow: 'rgba(144, 117, 242, 0.5)',
      backgroundOutner: '#0072ff',
      backgroundInner: '#f6f5ff',
      borderGradient: 'linear-gradient(180deg, #dbfffe 0%, #25dfff 100%), linear-gradient(0deg, #f6f5ff, #f6f5ff)',
      ellipsebackground: '#d1cef5',
      textButton: '#291ab4',
    },
    [StatusButton.hover]: {
      boxShadow: 'rgba(136, 177, 244, 0.5)',
      backgroundOutner: '#89E2FF',
      backgroundInner: '#f6f5ff',
      borderGradient: '#89E2FF',
      ellipsebackground: 'transparent',
      textButton: '#291ab4',
    },
    [StatusButton.active]: {
      boxShadow: 'rgba(91, 222, 192, 0.6)',
      backgroundOutner: '#FF9804',
      backgroundInner: '#FFFFE3',
      borderGradient: 'linear-gradient(180deg, #DEFF00 0%, #FEDF00 100%)',
      ellipsebackground: '#FFFEB3',
      textButton: '#EA7800',
    },
  },
};

const PlayNowButton: React.FC<
  Omit<ButtonProps, 'variant'> &
    Pick<TextProps, 'fontSize' | 'lineHeight'> & {
      variant?: PlayNowButtonTheme;
    }
> = ({ variant = PlayNowButtonTheme.DEFAULT, fontSize, lineHeight, ...props }) => {
  const varianStyled = variants[variant];

  const handlePlayGame = usePlayGame();

  return (
    <StyledButton variant={varianStyled} onClick={handlePlayGame} {...props}>
      <BoxGradient variant={varianStyled}>
        <StyledContaiener variant={varianStyled}>
          <Text
            mt={['0', '', '', '8px']}
            whiteSpace="nowrap"
            fontFamily="Martel"
            textAlign="center"
            fontWeight={900}
            fontSize={fontSize || ['20px', '', '28px', '34px', '46px']}
            lineHeight={lineHeight || ['20px', '', '28px', '34px', '46px']}
          >
            PLAY NOW!
          </Text>
        </StyledContaiener>
      </BoxGradient>
    </StyledButton>
  );
};

const BoxRadiusOutner = css`
  border-radius: 25px;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-radius: 35px;
  }
`;

const BoxRadiusInner = css`
  border-radius: 22px;

  ${({ theme }) => theme.mediaQueries.md} {
    border-radius: 32px;
  }
`;

const BoxGradient = styled(Box)<{ variant: VariantStatusStyled }>`
  width: inherit;
  box-sizing: border-box;
  height: calc(100% - 4px);
  background: ${({ variant }) => variant.default.borderGradient};
  padding: 3px;

  ${({ theme }) => theme.mediaQueries.sm} {
    height: calc(100% - 6px);
  }

  ${({ theme }) => theme.mediaQueries.md} {
    height: calc(100% - 12px);
  }

  ${BoxRadiusOutner}
`;

const StyledContaiener = styled(Flex)<{ variant: VariantStatusStyled }>`
  width: 100%;
  height: 100%;

  z-index: 2;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.4s all;
  background: ${({ variant }) => variant.default.backgroundInner};
  padding: 0 24px;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: ' ';

    transform: Translate(-25%, 30%);
    width: 200%;
    height: 100%;
    z-index: 1;
    background: ${({ variant }) => variant.default.ellipsebackground};
    /* box-shadow: 0px -8px 10px 12px rgba(144, 117, 242, 0.5); */
    clip-path: ellipse(27% 35% at 50% 50%);
  }

  ${Text} {
    position: relative;
    z-index: 1000;
    color: ${({ variant }) => variant.default.textButton};
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 40px;
  }

  ${BoxRadiusInner}
`;

const StyledButton = styled(Button)<{ variant: VariantStatusStyled }>`
  position: relative;
  display: flex;
  align-items: flex-start;

  box-shadow: 0px -8px 8px 2px ${({ variant }) => variant.default.boxShadow};
  background: ${({ variant }) => variant.default.backgroundOutner};
  width: ${({ width }) => width || '100%'};
  height: height;
  overflow: hidden;
  transition: 0.2s all;

  padding: 0;

  ${({ theme }) => theme.mediaQueries.md} {
    box-shadow: 0px -8px 10px 12px ${({ variant }) => variant.default.boxShadow};
  }

  ${({ theme }) => theme.mediaQueries.md} {
    box-shadow: 0px -8px 10px 12px ${({ variant }) => variant.default.boxShadow};
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    &:hover {
      box-shadow: 0px -8px 10px 12px ${({ variant }) => variant.hover.boxShadow};
      background: ${({ variant }) => variant.hover.backgroundOutner};

      ${BoxGradient} {
        background: ${({ variant }) => variant.hover.borderGradient};
        box-shadow: 0px 10px 14px -16px #000;
      }

      ${StyledContaiener} {
        background: ${({ variant }) => variant.hover.backgroundInner};

        &:before {
          transition: 0.2s all;
          background: ${({ variant }) => variant.hover.ellipsebackground};
        }

        ${Text} {
          color: ${({ variant }) => variant.hover.textButton};
        }
      }
    }

    &:active {
      box-shadow: 0px -8px 10px 12px ${({ variant }) => variant.active.boxShadow};
      background: ${({ variant }) => variant.active.backgroundOutner};

      ${BoxGradient} {
        background: ${({ variant }) => variant.active.borderGradient};
        box-shadow: 0px 10px 14px -16px #000;
      }

      ${StyledContaiener} {
        background: ${({ variant }) => variant.active.backgroundInner};

        &:before {
          transition: 0.2s all;
          background: ${({ variant }) => variant.active.ellipsebackground};
        }
        ${Text} {
          color: ${({ variant }) => variant.active.textButton};
        }
      }
    }
  }

  ${BoxRadiusOutner}
`;

export default PlayNowButton;
