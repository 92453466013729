import Box from 'components/Box/Box';
import Grid from 'components/Box/Grid';
import PokerButton, { VariantPokerButton } from 'components/Button/PokerButton';
import Text from 'components/Text';
import useMatchBreakpoints from 'hooks/useMatchBreakpoints';
import { useRouter } from 'hooks/useRouter';
import React, { useCallback, useEffect } from 'react';
import { useAppSelector } from 'state';
import styled from 'styled-components';
import AuthenticationService from 'services/AuthenticationService';
import useModal from 'hooks/useModal';
import { APK_URL } from 'config/constants/server';
import Link from 'components/Link/Link';
import { ActionQueryEnum } from 'hooks/useActionQueryListener';
import { isIOS, isMobile } from 'react-device-detect';
import QRLoginApp from './QRLoginApp';
import PlayNow from './PlayNow';

const HomeBottom: React.FC = () => {
  const router = useRouter();
  const clubCode = router.query.club;
  const { isDesktop } = useMatchBreakpoints();
  const [onPresentQR] = useModal(QRLoginApp);

  const isSigned = useAppSelector((state) => state.app.isSigned);

  const handleRedirectIntoGame = () => {
    router.push('/play', {
      query: {
        club: clubCode,
      },
    });
  };

  useEffect(() => {
    if (clubCode && isSigned) {
      handleRedirectIntoGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned]);

  const handleOpenApp = useCallback(async () => {
    if (!isSigned) {
      router.push({
        pathname: router.pathname,
        query: {
          ...(router.query || {}),
          action: ActionQueryEnum.Login,
        },
      });
      return;
    }

    const result = await AuthenticationService.getDeepLinkToken();
    if (result?.data?.token) {
      const paramsClubCode = clubCode ? `&ClubCode${clubCode}` : '';
      window.open(`unitydl://mylink?${result.data.token}${paramsClubCode}`, '_blank');
    }
  }, [clubCode, isSigned, router]);

  const handlePresentQRCode = () => {
    if (!isSigned) {
      router.push({
        pathname: router.pathname,
        query: {
          ...(router.query || {}),
          action: ActionQueryEnum.Login,
        },
      });
      return;
    }

    onPresentQR();
  };

  return (
    <>
      {!isDesktop && <PlayNow />}

      <StyledWrapper>
        {isDesktop && <PlayNow />}

        <StyledWrapListButton>
          {!isIOS && (
            <>
              {isMobile && !isIOS && (
                <StyledButtonOpenApp variant={VariantPokerButton.OPEN_APP} onClick={handleOpenApp}>
                  <img src="/images/open-app.png" alt="icon-open-app" />
                  <StyledText>Open App</StyledText>
                </StyledButtonOpenApp>
              )}

              <Link href={APK_URL} external>
                <StyledButtonDownload variant={VariantPokerButton.APK}>
                  <img src="/images/android.png" alt="icon-android" />
                  <StyledText>Direct APK Download</StyledText>
                </StyledButtonDownload>
              </Link>
            </>
          )}

          <StyledButtonScan variant={VariantPokerButton.SCAN} onClick={handlePresentQRCode}>
            <img src="/images/scan.png" alt="icon-scan" />
            <StyledText>Scan QR to login on App</StyledText>
          </StyledButtonScan>
        </StyledWrapListButton>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled(Box)`
  position: absolute;
  bottom: 32px;
  right: 16px;

  ${({ theme }) => theme.mediaQueries.lg} {
    bottom: 60px;
    right: 100px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    bottom: 100px;
    right: 140px;
  }
`;

const StyledWrapListButton = styled(Grid)`
  margin-left: auto;
  width: fit-content;

  grid-template-columns: 1fr;

  grid-gap: 10px;
  margin-top: 48px;

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-gap: 20px;
    margin-top: 48px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledText = styled(Text)`
  text-align: center;
  margin: 0 auto;
  font-weight: 700;

  font-size: 12px;
  line-height: 18px;
  padding-left: 4px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 14px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 14px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 16px;
    font-size: 16px;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 20px;

    line-height: 24px;
    margin-left: 0;
    padding: 0 16px;
  }
`;

const StyledButton = styled(PokerButton)`
  box-sizing: border-box;

  width: 160px;
  height: 50px;

  img {
    max-height: 24px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 160px;

    img {
      max-height: 30px;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 200px;
    height: 60px;
    img {
      max-height: 35px;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 240px;
    height: 80px;
    img {
      max-height: 40px;
    }
  }
`;

const StyledButtonOpenApp = styled(StyledButton)``;

const StyledButtonDownload = styled(StyledButton)``;

const StyledButtonScan = styled(StyledButton)``;

export default HomeBottom;
