import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import Carousel from 'components/Carousel/Carousel';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';
import useMatchBreakpoints from 'hooks/useMatchBreakpoints';
import { ColumnCenter } from 'layout/Components/Column';
import { RowCenter } from 'layout/Components/Row';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const GameGrallery: React.FC<BoxProps> = ({ ...props }) => {
  const { isMobile } = useMatchBreakpoints();

  const banners = useMemo(
    () =>
      [
        '/images/grallery/grallery-1.png',
        '/images/grallery/grallery-2.png',
        '/images/grallery/grallery-3.png',
        '/images/grallery/grallery-4.png',
        '/images/grallery/grallery-5.png',
      ].map((src, index) => ({
        key: `grallery-${index}`,
        content: (
          <StyledWrapImage>
            <Image src={src} width={640} height={1138} alt="image-grallery" />
          </StyledWrapImage>
        ),
      })),
    [],
  );

  const renderQuality = useMemo(
    () => <Image src="/images/poker-quality.png" width={!isMobile ? 180 : 100} height={!isMobile ? 23 : 14} />,
    [isMobile],
  );

  const slidePerView = useMemo(() => (isMobile ? 3 : 4), [isMobile]);

  return (
    <Wrapper mb={['-8px', '', '-60px', '', '-80px']} mx="auto" {...props}>
      <RowCenter>
        <ColumnCenter>
          {renderQuality}
          <Text
            textAlign="center"
            fontSize={['10px', '12px', '', '16px', '24px', '30px']}
            lineHeight={['18px', '', '24px', '32px', '36px']}
            fontWeight={700}
            my={['16px', '', '40px']}
          >
            WHY YOU SHOULD PLAY ON HUNNYPOKER
          </Text>
        </ColumnCenter>
      </RowCenter>

      <Carousel
        slidesPerView={slidePerView}
        pb={[`calc(17.78% * 10 / ${slidePerView} - 26px)`]}
        spaceBetween={isMobile ? 10 : 20}
        slides={banners}
        loop
        autoplay={{
          delay: 5000,
        }}
        pagination
      />
      <Box mt={['24px', '', '60px', '', '80px']}>{renderQuality} </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  padding: 0 12px;
  width: 100%;
  overflow: hidden;
  max-width: ${({ theme }) => theme.siteWidth}px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 24px;
  }
`;

const StyledWrapImage = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;

  scroll-snap-align: start;
  scroll-snap-stop: normal;
  border-radius: ${({ theme }) => theme.radii.small};

  ${({ theme }) => theme.mediaQueries.md} {
    border-radius: ${({ theme }) => theme.radii.large};
  }
`;

export default GameGrallery;
