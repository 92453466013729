import Home from 'views/Home';

export async function getStaticProps() {
  return {
    props: {
      layoutProps: { pt: '0 !important', maxWidth: '100vw' },
    },
  };
}

export default Home;
