import { FlexProps } from 'components/Box/types';
import PlayNowButton from 'components/Button/PlayNowButton';
import { ColumnCenter } from 'layout/Components/Column';
import React from 'react';
import styled from 'styled-components';

const PlayNow: React.FC<FlexProps> = ({ ...props }) => {
  return (
    <StyledWrapper {...props}>
      <PlayNowButton width="100%" height={['60px', '', '100px']} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled(ColumnCenter)`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, 0);

  max-width: 160px;

  a {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 320px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    bottom: unset;
    left: unset;
    transform: unset;
    max-width: 100%;
  }
`;
export default PlayNow;
