import React from 'react';
import styled from 'styled-components';
import Flex from 'components/Box/Flex';
import Box from 'components/Box/Box';
import { RowMiddle } from 'layout/Components/Row';
import Button from './Button';
import { ButtonProps } from './types';

export enum VariantPokerButton {
  OPEN_APP = 'OPEN_APP',
  APK = 'APK',
  SCAN = 'SCAN',
  WALLET = 'WALLET',
  BALANCE = 'BALANCE',
}

type VariantStatusStyled = {
  backgroundOutner: string;
  backgroundInner: string;
  borderGradient: string;
  ellipsebackground: string;
};

const varirantsTheme = {
  [VariantPokerButton.OPEN_APP]: {
    backgroundOutner: '#0D47A1',
    backgroundInner: 'linear-gradient(98.13deg, #2196F3 3.59%, #1565C0 70.34%)',
    ellipsebackground: '#0D47A1',
    borderGradient: '#1565C0',
  },
  [VariantPokerButton.APK]: {
    backgroundOutner: '#040404',
    backgroundInner: 'linear-gradient(91.34deg, #4B4747 -3.91%, #2D2C2C 102.6%)',
    ellipsebackground: '#201F1F',
    borderGradient: 'linear-gradient(180deg, #9F9F9F 0%, #444444 100%)',
  },
  [VariantPokerButton.SCAN]: {
    backgroundOutner: '#F07803',
    backgroundInner: 'linear-gradient(98.13deg, #F9DC26 3.59%, #FF8308 70.34%)',
    ellipsebackground: '#F07803',
    borderGradient: '#FBBB1B',
  },
  [VariantPokerButton.WALLET]: {
    backgroundOutner: '#FF4081',
    backgroundInner: 'linear-gradient(91.34deg, #F06292 -3.91%, #D81B60 102.6%) ',

    ellipsebackground: '#E91E63',
    borderGradient: 'linear-gradient(180deg, #F48FB1 0%, #F06292 100%)',
  },
  [VariantPokerButton.BALANCE]: {
    backgroundOutner: '#37474f',
    backgroundInner: '#37474F',
    ellipsebackground: '#37474f',
    borderGradient: '#34434a',
  },
};

const PokerButton: React.FC<
  Omit<ButtonProps, 'variant'> & {
    variant?: VariantPokerButton;
  }
> = ({ children, variant = VariantPokerButton.APK, ...props }) => {
  const varianStyled = varirantsTheme[variant];
  return (
    <StyledButton variant={varianStyled} {...props}>
      <BoxGradient variant={varianStyled}>
        <StyledContaiener variant={varianStyled}>
          <StyledContent>{children}</StyledContent>
        </StyledContaiener>
      </BoxGradient>
    </StyledButton>
  );
};

const BoxGradient = styled(Box)<{ variant: VariantStatusStyled }>`
  width: inherit;
  box-sizing: border-box;
  height: calc(100% - 2px);
  transition: 0.2s all;
  background: ${({ variant }) => variant.borderGradient};
  border-radius: 10px;
  padding: 2px;

  ${({ theme }) => theme.mediaQueries.xl} {
    border-radius: 25px;
    height: calc(100% - 6px);
  }
`;

const StyledContent = styled(RowMiddle)`
  color: #fff;
`;

const StyledContaiener = styled(Flex)<{ variant: VariantStatusStyled }>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 0 12px;

  z-index: 2;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.4s all;
  background: ${({ variant }) => variant.backgroundInner};

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: ' ';

    transform: Translate(-25%, 30%);
    width: 200%;
    height: 100%;
    z-index: 0;
    background: ${({ variant }) => variant.ellipsebackground};
    clip-path: ellipse(27% 40% at 50% 50%);
  }

  ${StyledContent} {
    position: relative;
    z-index: 1000;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    border-radius: 23px;
  }
`;

const StyledButton = styled(Button)<{ variant: VariantStatusStyled }>`
  position: relative;
  display: flex;
  align-items: flex-start;

  background: ${({ variant }) => variant.backgroundOutner};
  width: 100%;
  height: auto;
  overflow: hidden;
  transition: 0.2s all;

  border-radius: 10px;
  padding: 0;

  ${({ theme }) => theme.mediaQueries.xl} {
    border-radius: 25px;
  }
`;

export default PokerButton;
