import React from 'react';
import styled from 'styled-components';

import { ColumnCenter } from 'layout/Components/Column';
import Text from 'components/Text';
import HunnySocial from 'layout/Components/HunnySocial';
import Box from 'components/Box/Box';
import HomeBottom from './components/HomeBottom';
import GameGrallery from './components/GameGrallery';
import HomeFooter from './components/HomeFooter';

const HomePage: React.FC = () => {
  return (
    <StyledWrapper>
      <HunnySocial />

      <StyledContainerBanner>
        <StyledWrapLogo>
          <StyledLogo src="/images/logo-full.png" />
          <StyledHeading>THE MOST ENGAGING AND FUN POKER GAME ON CHAIN!</StyledHeading>
        </StyledWrapLogo>

        <HomeBottom />
      </StyledContainerBanner>

      <Box py={['24px', '', '40px']} width="100%">
        <GameGrallery />
        <HomeFooter />
      </Box>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(ColumnCenter)`
  width: 100%;
  height: 100%;
  background: #170e55;
`;

const StyledContainerBanner = styled(ColumnCenter)`
  padding: calc(25px + ${({ theme }) => theme.topbarHeight} + var(--notification-bar-height)) 0 0;

  background-image: url('/images/background.png');
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  min-height: 100vh;

  ${({ theme }) => theme.mediaQueries.xs} {
    min-height: 70vh;
  }

  @media (max-height: 700px) {
    min-height: 100vh;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    min-height: 100vh;
    padding: calc(25px + ${({ theme }) => theme.topbarHeight} + var(--notification-bar-height)) 64px;
  }
`;

const StyledLogo = styled.img`
  width: 100%;
  margin-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 32px;
  }
`;

const StyledHeading = styled(Text)`
  font-family: 'Montserrat';

  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #e5e5e5;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 20px;
    line-height: 32px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 24px;
    line-height: 40px;
  }
`;

const StyledWrapLogo = styled(ColumnCenter)`
  max-width: 80%;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 400px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 550px;
  }
`;
export default HomePage;
