import Modal, { ModalProps } from 'components/Modal/Modal';
import { ActionQueryEnum } from 'hooks/useActionQueryListener';
import { useCountDown } from 'hooks/useCountdown';
import { useRouter } from 'hooks/useRouter';
import { ColumnCenter } from 'layout/Components/Column';
import QRCode from 'qrcode.react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import AuthenticationService from 'services/AuthenticationService';
import { useAuth } from 'state/auth/hooks';
import styled from 'styled-components';
import { RowCenter } from 'layout/Components/Row';
import Box from 'components/Box/Box';
import Heading from 'components/Heading';
import CircleLoader from 'components/Loader/CircleLoader';
import { ModalHeader, ModalBody } from 'components/Modal';
import Text from 'components/Text/Text';
import { getNextSeconds } from 'utils/dateHelper';
import Link from 'components/Link/Link';
import { APK_URL } from 'config/constants/server';

const QRLoginApp: React.FC<ModalProps> = ({ onDismiss }) => {
  const { hasSession } = useAuth();
  const router = useRouter();
  const [tokenDeepLink, setTokenDeepLink] = useState('');
  const [timeGenerateQR, setTimeGenerateQR] = useState(0);

  const fetchData = useCallback(async () => {
    const data = await AuthenticationService.getDeepLinkToken();
    setTokenDeepLink(data?.data?.token || '');
    setTimeGenerateQR(getNextSeconds(1));
  }, []);

  useEffect(() => {
    if (hasSession) {
      fetchData();
    } else {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          action: ActionQueryEnum.Login,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession]);

  const resendCodeTextRef = useCountDown(
    timeGenerateQR,
    (s) => `00:${s.toString().padStart(2, '0')}`,
    () => {
      fetchData();
    },
  );

  const renderQRCode = useMemo(
    () =>
      tokenDeepLink ? (
        <StyledQRCode value={tokenDeepLink} />
      ) : (
        <RowCenter width="100%" height="100%">
          <CircleLoader />
        </RowCenter>
      ),
    [tokenDeepLink],
  );

  return (
    <Modal onDismiss={onDismiss}>
      <ModalHeader px={['12px', '', '24px']}>
        <Heading>
          <Trans>Scan QR Code to Login on App</Trans>
        </Heading>
      </ModalHeader>
      <ModalBody p={['12px', '', '24px']}>
        <ColumnCenter my="24px">
          <StyledContainerQRCode>{renderQRCode}</StyledContainerQRCode>

          <Text fontWeight={700} mt="12px" fontSize={['14px', '', '16px']} ref={resendCodeTextRef}>
            ...
          </Text>
        </ColumnCenter>
        <Box maxWidth="90%" mx="auto">
          <StyledStep>
            Step 1: Install the “HunnyPoker” app on your mobile device. You can download it
            <Link
              fontWeight={600}
              color="#cb00ff"
              ml="4px"
              href={APK_URL}
              external
              style={{
                display: 'inline-block',
              }}
            >
              here
            </Link>
            .
          </StyledStep>
          <StyledStep>
            Step 2: Open the HunnyPoker app, go to the “Wallet” tab, and click on the “Scan QR code” button.
          </StyledStep>
          <StyledStep>Step 3: Scan the QR code above to log in to your account on that device.</StyledStep>
        </Box>
      </ModalBody>
    </Modal>
  );
};

const StyledContainerQRCode = styled(Box)`
  padding: 12px;
  background: #fff;
  border-radius: ${({ theme }) => theme.radii.small};

  width: 240px !important;
  height: 240px !important;
`;
const StyledQRCode = styled(QRCode)`
  width: 100% !important;
  height: 100% !important;
`;

const StyledStep = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  margin: 6px 0;
  color: ${({ theme }) => theme.colors.text};

  padding-left: 16px;
  position: relative;
  &::before {
    content: '•'; /* the dot */
    font-size: 2.2em;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-15%);
  }
`;
export default QRLoginApp;
